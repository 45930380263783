<template>
  <div>
    <div class="d-block">
      <div
        class="
          text-xs-h5 text-sm-h5 text-md-h4 text-lg-4 text-xl-4
          success--text
          d-inline
        "
      >
        Ученики
        <v-btn
          v-if="$store.getters.profile.user_access_level == 'training_officer'"
          @click="form_dialog = true"
          color="success"
          class="float-right"
          outlined
          >Новый Ученик</v-btn
        >
      </div>
    </div>
    <div>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="(student, index) in students">
            <v-list-item :key="student.id">
              <v-list-item-avatar>
                <v-img height="100%" :src="api + student.user.image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >{{ student.user.first_name }}
                  {{ student.user.last_name }}</v-list-item-title
                >

                <v-list-item-subtitle
                  v-text="student.direction.name"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index < students.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>
    <v-dialog max-width="540px" v-model="form_dialog">
      <v-card>
        <v-card-title>
          Создать ученика
          <v-spacer></v-spacer>
          <v-icon @click="form_dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.first_name"
                  label="Имя"
                  :error-messages="FirstNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.middle_name"
                  label="Отчество"
                  :error-messages="MiddleNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.last_name"
                  label="Фамилия"
                  :error-messages="LastNameErrors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="form.email"
                  :error-messages="EmailErrors"
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  prepend-icon="mdi-plus"
                  v-model="form.phone"
                  :error-messages="PhoneErrors"
                  label="Телефонный номер"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="course"
                  :error-messages="CourseErrors"
                  :items="courses"
                  item-text="name"
                  item-value="id"
                  label="Выбрать курс"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="form.password"
                  :error-messages="PasswordErrors"
                  type="password"
                  label="Пароль"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="password_confirm"
                  :error-messages="PasswordConfirmErrors"
                  type="password"
                  label="Подтвердите пароль"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      absolute
      right
      top
      color="green"
      elevation="6"
      multi-line
      tile
      class="snackbar"
      transition="slide-x-reverse-transition"
    >
      O'quvchi muvafaqqiyatli qo'shildi
    </v-snackbar>
  </div>
</template>

<script>
import { STUDENT_GET, STUDENT_POST } from "@/store/actions/student";
import { COURSE_GET } from "@/store/actions/other";
import { USER_REGISTRATION } from "@/store/actions/users";
import { Check_email, phone } from "@/utils/validators";
import { required, sameAs, minLength, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      students: [],
      courses: [],
      course: "",
      form_dialog: false,
      snackbar: false,
      form: {
        first_name: "",
        last_name: "",
        middle_name: "",
        password: "",
        phone: "",
        email: "",
        user_access_level: "student",
      },
      password_confirm: "",
      api: process.env.VUE_APP_API_URL.slice(0, -1),
    };
  },
  methods: {
    async LoadStudents() {
      let resp = await this.$dispatch(STUDENT_GET, "?is_started=false");
      if (resp.success) {
        this.students = resp.result;
      } else {
        alert(resp);
      }
    },
    async LoadCourses() {
      let resp = await this.$dispatch(COURSE_GET);
      if (resp.success) {
        this.courses = resp.result;
      } else {
        alert(resp);
      }
    },
    async save() {
      this.$v.form.$touch();
      this.$v.course.$touch();
      this.$v.password_confirm.$touch();
      if (
        !this.$v.form.$invalid &&
        !this.$v.course.$invalid &&
        !this.$v.password_confirm.$invalid
      ) {
        let resp = await this.$dispatch(USER_REGISTRATION, this.form);
        if (resp.success) {
          let resp2 = await this.$dispatch(STUDENT_POST, {
            user: resp.result.id,
            direction: this.course.id,
          });
          if (resp2.success) {
            this.LoadStudents();
            this.form_dialog = false;
            this.snackbar = true;
          }
        } else {
          alert(resp.result);
        }
      }
    },
  },
  computed: {
    FirstNameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required &&
        errors.push("Имя обязательно");
      return errors;
    },
    LastNameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push("Фамилия обязательна");
      return errors;
    },
    MiddleNameErrors() {
      const errors = [];
      if (!this.$v.form.middle_name.$dirty) return errors;
      !this.$v.form.middle_name.required &&
        errors.push("Требуется отчество");
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Необходим пароль");
      !this.$v.form.password.minLength &&
        errors.push("Пароль должен содержать не менее 6 символов.");
      return errors;
    },
    PasswordConfirmErrors() {
      const errors = [];
      if (!this.$v.password_confirm.$dirty) return errors;
      !this.$v.password_confirm.sameAsPassword &&
        errors.push("Подтверждение пароля не совпадает");
      !this.$v.password_confirm.required &&
        errors.push("Требуется подтверждение пароля");
      return errors;
    },
    EmailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Должен быть действующий email");
      !this.$v.form.email.required && errors.push("Электронная почта обязательна");
      !this.$v.form.email.Check_email &&
        errors.push("Этот email уже существует.");
      return errors;
    },
    PhoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.required && errors.push("Номер телефона обязателен");
      !this.$v.form.phone.phone && errors.push("Номер телефона недействителен или уже существует.");
      return errors;
    },
    CourseErrors() {
      const errors = [];
      if (!this.$v.course.$dirty) return errors;
      !this.$v.course.required && errors.push("Курс обязателен");
      return errors;
    },
  },
  validations() {
    return {
      form: {
        first_name: { required },
        last_name: { required },
        middle_name: { required },
        password: { required, min: minLength(6) },
        phone: { required, phone },
        email: { required, Check_email, email },
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs(function () {
          return this.form.password;
        }),
      },
      course: { required },
    };
  },
  created() {
    this.LoadStudents();
    this.LoadCourses();
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";
</style>